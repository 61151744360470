import { UserAvatarBadgeType } from '@/controllers/graphql/generated';
import { IconBadgeAdmin } from '@/components/ui/icons/IconBadgeAdmin';
import {
  IconBadgeRoleModerator,
} from '@/components/ui/icons/IconBadgeRoleModerator';
import {
  IconBadgeRoleExternalMentor,
} from '@/components/ui/icons/IconBadgeRoleExternalMentor';
import { IconBadgeRoleSales } from '@/components/ui/icons/IconBadgeRoleSales';
import {
  IconBadgeRoleRecruiter,
} from '@/components/ui/icons/IconBadgeRoleRecruiter';
import { IconBadgePro } from '@/components/ui/icons/IconBadgePro';
import { IconBadgeUnlim } from '@/components/ui/icons/IconBadgeUnlim';
import { IconBadgeBro } from '@/components/ui/icons/IconBadgeBro';
import {
  IconBadgeProfessionDesign,
} from '@/components/ui/icons/IconBadgeProfessionDesign';
import {
  IconBadgeProfessionFrontend,
} from '@/components/ui/icons/IconBadgeProfessionFrontend';
import {
  IconBadgeProfessionJava,
} from '@/components/ui/icons/IconBadgeProfessionJava';
import {
  IconBadgeProfessionFullstack,
} from '@/components/ui/icons/IconBadgeProfessionFullstack';
import {
  IconBadgeProfessionRecruitment,
} from '@/components/ui/icons/IconBadgeProfessionRecruitment';
import {
  IconBadgeProfessionPython,
} from '@/components/ui/icons/IconBadgeProfessionPython';
import {
  IconBadgeProfessionQa,
} from '@/components/ui/icons/IconBadgeProfessionQa';
import { IconFireWithBackground } from '@/components/ui/icons/IconFireWithBackground';
import { IconWorkOn } from '@/components/ui/icons/IconWorkOn';
import { IconJobChange } from '@/components/ui/icons/IconJobChange';
import { FCIcon } from '@/components/ui/icons/typedefs';

const BadgeTypeToIconMap: Record<
  UserAvatarBadgeType,
  FCIcon
> = {
  [UserAvatarBadgeType.Admin]: IconBadgeAdmin,
  [UserAvatarBadgeType.RoleModerator]: IconBadgeRoleModerator,
  [UserAvatarBadgeType.RoleExternalMentor]: IconBadgeRoleExternalMentor,
  [UserAvatarBadgeType.RoleSales]: IconBadgeRoleSales,
  [UserAvatarBadgeType.RoleRecruiter]: IconBadgeRoleRecruiter,
  [UserAvatarBadgeType.Pro]: IconBadgePro,
  [UserAvatarBadgeType.Unlim]: IconBadgeUnlim,
  [UserAvatarBadgeType.Bro]: IconBadgeBro,
  [UserAvatarBadgeType.ProfessionDesign]: IconBadgeProfessionDesign,
  [UserAvatarBadgeType.ProfessionFrontend]: IconBadgeProfessionFrontend,
  [UserAvatarBadgeType.ProfessionJava]: IconBadgeProfessionJava,
  [UserAvatarBadgeType.ProfessionFullstack]: IconBadgeProfessionFullstack,
  [UserAvatarBadgeType.ProfessionRecruitment]: IconBadgeProfessionRecruitment,
  [UserAvatarBadgeType.ProfessionPython]: IconBadgeProfessionPython,
  [UserAvatarBadgeType.ProfessionQa]: IconBadgeProfessionQa,
  [UserAvatarBadgeType.Streak]: IconFireWithBackground,
  [UserAvatarBadgeType.Employed]: IconWorkOn,
  [UserAvatarBadgeType.JobChange]: IconJobChange,
};

export const resolveBadgeIcon = (
  type?: UserAvatarBadgeType | null,
) => {
  if (!type) {
    return null;
  }

  return BadgeTypeToIconMap[type];
};
