import { AchievementIconHarnessSelectors } from '@/components/platform/Achievements/components/AchievementIcon.harness';
import { ComponentHarness } from '@/testing/cypress/support/ComponentHarness';

export enum UserProfileHarnessSelectors {
  DataQa = 'user_profile',
  MainView = 'main_view',
  FullAchievementsListView = 'full_achievements_list_view',
  MainInfoBlock = 'user-profile-info',
  SendDmButton = 'direct-message-button',
  PrivateProfileBadge = 'private-profile-badge',
  SocialLinksBlock = 'social-links',
  StatisticBlock = 'statistic-block',
  CurrentProgressBlock = 'current-progress-block',
  AchievementsBlock = 'achievements-block',
  ViewAllAchievementsButton = 'view-all-achievements-button',
  CloseProfileButton = 'close-profile-button',
  AllAchievementsBackButton = 'all-achievements-back-button',
  AchievementItem = 'amount-of-achievements-items',
}

export class UserProfileHarness extends ComponentHarness {
  dataQa = UserProfileHarnessSelectors.DataQa;

  get mainView() {
    return cy.get(`[data-qa="${UserProfileHarnessSelectors.MainView}"]`);
  }

  get fullAchievementsListView() {
    return cy.get(`[data-qa="${UserProfileHarnessSelectors.FullAchievementsListView}"]`);
  }

  get userProfileMainInfoBlock() {
    return cy.get(`[data-qa="${UserProfileHarnessSelectors.MainInfoBlock}"]`);
  }

  get sendDmButton() {
    return cy.get(`[data-qa="${UserProfileHarnessSelectors.SendDmButton}"]`);
  }

  get privateProfileBadge() {
    return cy.get(`[data-qa="${UserProfileHarnessSelectors.PrivateProfileBadge}"]`);
  }

  get socialLinksBlock() {
    return cy.get(`[data-qa="${UserProfileHarnessSelectors.SocialLinksBlock}"]`);
  }

  get statisticBlock() {
    return cy.get(`[data-qa="${UserProfileHarnessSelectors.StatisticBlock}"]`);
  }

  get currentProgressBlock() {
    return cy.get(`[data-qa="${UserProfileHarnessSelectors.CurrentProgressBlock}"]`);
  }

  get achievementsBlock() {
    return cy.get(`[data-qa="${UserProfileHarnessSelectors.AchievementsBlock}"]`);
  }

  get viewAllAchievementsButton() {
    return cy.get(`[data-qa="${UserProfileHarnessSelectors.ViewAllAchievementsButton}"]`);
  }

  get closeProfileButton() {
    return cy.get(`[data-qa="${UserProfileHarnessSelectors.CloseProfileButton}"]`);
  }

  get allAchievementsBackButton() {
    return cy.get(`[data-qa="${UserProfileHarnessSelectors.AllAchievementsBackButton}"]`);
  }

  get achievementItems() {
    return cy.get(`[data-qa="${UserProfileHarnessSelectors.AchievementItem}"]`);
  }

  checkPublicInfoIsHidden = () => {
    this.currentProgressBlock.should('not.exist');

    this.achievementsBlock.should('not.exist');

    this.statisticBlock.should('not.exist');

    this.socialLinksBlock.should('not.exist');
  };

  assertAchievementsBlockHasUnlockedAchievements = () => {
    this.achievementItems.should('have.length', 2);

    this.achievementItems
      .eq(0)
      .should('contain.text', 'name.ACHIEVEMENT_1')
      .and('have.attr', 'data-qa-unlocked', 'true')
      .find(`[data-qa="${AchievementIconHarnessSelectors.DataQa}"]`)
      .should('exist')
      .and('have.attr', 'alt', 'name.ACHIEVEMENT_1');

    this.achievementItems
      .eq(1)
      .should('contain.text', 'name.ACHIEVEMENT_2')
      .and('have.attr', 'data-qa-unlocked', 'true')
      .find(`[data-qa="${AchievementIconHarnessSelectors.DataQa}"]`)
      .should('exist')
      .and('have.attr', 'alt', 'name.ACHIEVEMENT_2');
  };
}
