/**
 * Debounce function to limit the number of times a function is called.
 * @param func - function to be debounced
 * @param delay - time in milliseconds to wait before calling the function
 * @returns debounced function with additional methods `force` and `clear`
 */
export const debounce = <
  T extends (...args: any[]) => any
>(func: T, delay = 250): T & {
  force: T;
  clear: () => void;
} => {
  let timer: NodeJS.Timeout;

  const debounced = ((...args: Parameters<T>) => {
    clearTimeout(timer);
    timer = setTimeout(() => func(...args), delay);
  }) as T & {
    force: T;
    clear: () => void;
  };

  debounced.force = func;
  debounced.clear = () => {
    clearTimeout(timer);
  };

  return debounced;
};
